import store from '../store'
const hexToArrayBuffer = require('hex-to-array-buffer')

export default {
  initiateConnection (window) {
    window.chrome.sockets.tcp.create(createInfo => {
      const socketTcpId = createInfo.socketId
      store.dispatch('socketTcpId', socketTcpId)
      console.log('Socket Initiated')
      console.log(socketTcpId)
      window.chrome.sockets.tcp.onReceive.addListener(async info => {
        const data = await arrayBuffer2str(info.data)
        const removedCharacters = await data.substring(1, data.length - 1)
        console.log('GW RESPONSE ' + removedCharacters)
        const gatewayResponse = await JSON.parse(removedCharacters)
        store.dispatch('tcpData', gatewayResponse)
      })
      window.chrome.sockets.tcp.onReceiveError.addListener(async info => {
        console.log('from error catcher : ' + JSON.stringify(info))
        store.dispatch('tcpStatus', { status: 'error' })
        this.closeTcp(window, socketTcpId)
      })
    })
    console.log('socket created')
  },
  connectToTheGateway (window, socketTcpId, gatewayIp, tcpPort, gwType) {
    console.log('socket trying to connect')
    // if (gwType === 'SEG-X3') {
    console.log(gwType)
    window.chrome.sockets.tcp.connect(socketTcpId, gatewayIp, tcpPort, (result) => {
      console.log(result)
      if (result === 0) {
        console.log('Successfully connected to the Gateway')
        store.dispatch('tcpStatus', { status: 'OK' })
      } else {
        console.log('error')
      }
    })
    // } else if (gwType === 'SEG-X1') {
    //   console.log(typeof gatewayIp)
    //   console.log('|' + gatewayIp + '|')
    //   console.log(typeof tcpPort)
    //   console.log(tcpPort)
    //   console.log(gwType)
    //   window.chrome.sockets.tcp.connect(socketTcpId, gatewayIp, tcpPort, (result) => {
    //     console.log(result)
    //     if (result === 0) {
    //       console.log('Successfully connected to the Gateway')
    //       store.dispatch('tcpStatus', { status: 'OK' })
    //     } else {
    //       console.log('error')
    //     }
    //   })
    // }
  },
  disconnectGateway (window, socketTcpId) {
    window.chrome.sockets.tcp.disconnect(socketTcpId)
    store.dispatch('tcpStatus', { status: 'notConnected' })
  },
  async sendCommandToGateway (window, socketTcpId, command) {
    window.chrome.sockets.tcp.getInfo(socketTcpId, async socketInfo => {
      if (!socketInfo.connected) {
        console.log('TCP Socket send command failed - not connected to socket(CordovaSocks)')
        store.dispatch('tcpStatus', { status: 'notConnected' })
        this.closeTcp(window, socketTcpId)
      } else {
        const data2send = await str2arrayBuffer(command)
        window.chrome.sockets.tcp.send(socketTcpId, data2send)
      }
    })
  },
  closeTcp (window, socketTcpId) {
    window.chrome.sockets.tcp.close(socketTcpId, () => {
      store.dispatch('socketTcpId', null)
      console.log('socket closed by prg')
    })
  }
}

function arrayBuffer2str (buf) {
  return String.fromCharCode.apply(null, new Uint8Array(buf))
}

async function str2arrayBuffer (str) {
  const intArray = str.split('').map(function (c) { return c.charCodeAt(0).toString(16) })
  intArray.unshift('02')
  intArray.push('03')
  const newArr = intArray.join('')
  const bufferArray = await hexToArrayBuffer(newArr)
  return bufferArray
}
